<template>
    <div class="agencyRenewal" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row style="padding-top: 20px">
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input
                                clearable
                                v-model="form.name"
                                maxlength="50"
                                show-word-limit
                                placeholder="机构名称"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="营业执照">
                            <el-input
                                clearable
                                maxlength="50"
                                show-word-limit
                                v-model="form.deptLicenseName"
                                placeholder="营业执照名称"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.finance.dept.renew.show')"
                >查询
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" baseUrl="/finance/settle/daily" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="机构组" prop="deptGroupName" />
                <el-table-column label="机构" prop="deptName" />
                <el-table-column label="营业执照名称" prop="deptLicenseName" />
                <el-table-column label="到期时间" prop="effectiveTime" />
                <el-table-column label="操作" header-align="center" prop="operate" key="operate">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="renewal(scope.row)" type="primary">一键续费 </el-button>
                        <el-button size="mini" @click="showLog(scope.row)">操作记录 </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="一键续费" width="30%" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="dialogForm">
                <el-form-item label="服务名称" :label-width="formLabelWidth">
                    <span>八号地综合零售云系统</span>
                </el-form-item>
                <el-form-item label="续费机构" :label-width="formLabelWidth">
                    <span>{{ dialogForm.deptName }}</span>
                </el-form-item>
                <el-form-item label="到期时间" :label-width="formLabelWidth">
                    <span>{{ dialogForm.effectiveTime }}</span>
                </el-form-item>
                <el-form-item label="续费类型" :label-width="formLabelWidth">
                    <template>
                        <el-radio-group v-model="dialogForm.reason">
                            <el-radio :value="1" :label="1">门店续费</el-radio>
                            <el-radio :value="2" :label="2">八号地赠送</el-radio>
                        </el-radio-group>
                    </template>
                </el-form-item>
                <el-form-item label="兑换时长" :label-width="formLabelWidth">
                    <span>12个月</span>
                </el-form-item>
                <el-form-item label="缴费时间" v-if="dialogForm.reason === 1" :label-width="formLabelWidth">
                    <el-date-picker
                        v-model="dialogForm.payTime"
                        size="small"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择日期时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="赠送原因" v-if="dialogForm.reason === 2" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="dialogForm.giftReason" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="clickRenewal">确 定</el-button>
            </div>
        </el-dialog>
        <!--        操作日志-->
        <el-dialog title="操作记录" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
            <el-table :data="logData" max-height="600">
                <el-table-column property="operator" label="续费人" width="150"></el-table-column>
                <el-table-column property="operateTime" label="操作时间" width="160"></el-table-column>
                <el-table-column property="reasonStr" label="续费类型"></el-table-column>
                <el-table-column property="giftReason" label="赠送原因" width="200"></el-table-column>
                <el-table-column property="afterTime" label="续费后到期时间" width="160"></el-table-column>
                <el-table-column property="payTime" label="缴费时间" width="160"></el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="logDataPage.pageSizes"
                :page-size="logDataPage.limit"
                :layout="logDataPage.PageStyle"
                :total="logDataPage.total"
                @size-change="handleSizeChangeLogData"
                @current-change="handleCurrentChangeLogData"
                @prev-click="handlePrevClickLogData"
                @next-click="handleNextClickLogData"
            />
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import { DeptTypeEnum } from 'js/DeptCommon';
import EfReviewBiz from 'components/EfReviewBiz';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'AgencyRenewal',
    mixins: [CheckTableShowColumn],
    // eslint-disable-next-line vue/no-unused-components
    components: { EfReviewBiz, CheckTableShowColumnDialog, CheckTableShowColumn },
    data() {
        return {
            form: {
                name: '',
                deptLicenseName: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            depts: [],
            tableData: [],
            url: {
                page: '/finance/dept/renew/pageExtend',
                renew: '/finance/dept/renew/renew',
                reviewLog: '/finance/dept/renew/log',
            },
            dialogFormVisible: false,
            dialogTableVisible: false, //操作日志弹窗
            formLabelWidth: '80px',
            dialogForm: {
                giftReason: '',
                reason: 1,
                payTime: '',
            },
            logData: [],
            logDataPage: {
                total: 0,
                page: 1,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
                limit: Util.Limit,
            },
            nowChooseItem: {},
            logDept: {},
        };
    },
    mounted() {
        const _this = this;
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            _this.depts = rst;
            // _this.form.deptCode = this.depts[0].code;
            _this.handleQuery();
        });
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        //一键续费
        renewal(item) {
            this.dialogFormVisible = true;
            this.dialogForm = item;
            this.$nextTick(() => {
                this.dialogForm = {
                    reason: 1,
                    deptCode: item.deptCode,
                    deptGroupName: item.deptGroupName,
                    deptName: item.deptName,
                    deptLicenseName: item.deptLicenseName,
                    effectiveTime: item.effectiveTime,
                };
            });
            this.nowChooseItem = item;
        },
        //确认续费
        clickRenewal() {
            if (this.dialogForm.reason == null) {
                this.$message.warning('请选择续费类型');
                return false;
            }
            const _params = {
                giftReason: this.dialogForm.giftReason,
                reason: this.dialogForm.reason,
                deptCode: this.dialogForm.deptCode,
                payTime: this.dialogForm.payTime,
                message: '将对选择门店进行续费12个月，是否确认？',
            };
            UrlUtils.PostPriceBatchRemote(this, this.url.renew, _params, null, () => {
                this.$message.success('操作成功');
                this.dialogFormVisible = false;
                this.handleQuery();
            });
        },
        //操作记录
        showLog(item) {
            this.dialogTableVisible = true;
            this.logDept = item;
            var _params = {
                deptCode: item.deptCode,
                page: this.logDataPage.page,
                limit: this.logDataPage.limit,
            };
            this.$http.get(this.url.reviewLog, { params: _params }).then((res) => {
                this.logData = res.data.data;
                this.logDataPage.total = res.data.count;
            });
        },
        handleSizeChangeLogData(size) {
            this.logDataPage.page = 1;
            this.logDataPage.limit = size;
            var _params = {
                deptCode: this.logDept.deptCode,
                page: this.logDataPage.page,
                limit: this.logDataPage.limit,
            };
            this.$http.get(this.url.reviewLog, { params: _params }).then((res) => {
                this.logData = res.data.data;
                this.logDataPage.total = res.data.count;
            });
        },
        handleCurrentChangeLogData(page) {
            this.logDataPage.page = page;
            var _params = {
                deptCode: this.logDept.deptCode,
                page: this.logDataPage.page,
                limit: this.logDataPage.limit,
            };
            this.$http.get(this.url.reviewLog, { params: _params }).then((res) => {
                this.logData = res.data.data;
                this.logDataPage.total = res.data.count;
            });
        },
        handlePrevClickLogData(page) {
            this.logDataPage.page = page;
            var _params = {
                deptCode: this.logDept.deptCode,
                page: this.logDataPage.page,
                limit: this.logDataPage.limit,
            };
            this.$http.get(this.url.reviewLog, { params: _params }).then((res) => {
                this.logData = res.data.data;
                this.logDataPage.total = res.data.count;
            });
        },
        handleNextClickLogData(page) {
            this.logDataPage.page = page;
            var _params = {
                deptCode: this.logDept.deptCode,
                page: this.logDataPage.page,
                limit: this.logDataPage.limit,
            };
            this.$http.get(this.url.reviewLog, { params: _params }).then((res) => {
                this.logData = res.data.data;
                this.logDataPage.total = res.data.count;
            });
        },
    },
};
</script>
